import { apiClient } from 'api/apiClient/apiClient'
import { useTranslation } from 'react-i18next'
import { UseQueryResult, useMutation, useQuery } from 'react-query'
import { useAlert } from 'ui/components/AlertContext/AlertContext'
import { useAuth } from 'ui/components/AuthContext/AuthContext'

import { queryClient } from 'helpers/queryClient'

export type OptJobType =
  | `regular`
  | `followup_with_deviations`
  | `followup_without_deviations`
  | `followup_regular`
  | `measvalues_calculations`
  | `marginal_cost_calculation`
  | `sandbox`
  | 'real_time'
  | 'real_time_measvalues_calculations'
  | 'fuel_plan'

export type OptJobStatus = `Created` | `Queued` | `Running` | `Failed` | `Finished` | `Unsolvable` | undefined

export type OptJobUnitSetting = {
  start_level: number
  end_level: number
}

export type OptJobInputData = {
  opt_start_time: string
  opt_end_time?: string
  unit_settings?: Record<string, OptJobUnitSetting>
  include_subtypes?: string[]
}

type OptJobPostData = {
  opt_model?: number
  opt_job_type: OptJobType
  input_data?: OptJobInputData
}

export type OptJobStatusObject = {
  created_at: string
  id: number
  opt_job_type: OptJobType
  opt_model?: number
  system?: number
  status: OptJobStatus
  updated_at: string
}

export const OPT_JOBS_QUERY_KEY = `optJobs`

export function getOptJobLatestKey({
  sandboxProjectId,
  systemId,
  type,
  status,
}: {
  sandboxProjectId?: number
  systemId?: number
  type: OptJobType
  status?: OptJobStatus
}) {
  return [OPT_JOBS_QUERY_KEY, `latest`, { sandboxProjectId, systemId, type, status }]
}

export function getOptjobAsObject(
  latestOptJob: OptJobStatusObject | OptJobStatusObject[] | [] | undefined,
  index?: number
): OptJobStatusObject | undefined {
  const jobIndex = index ?? 0
  if (latestOptJob) {
    if (Array.isArray(latestOptJob)) {
      if (latestOptJob.length > jobIndex) {
        return latestOptJob[jobIndex]
      } else {
        return undefined
      }
    }
    return latestOptJob
  }
}

export function useLatestOptJobStatus({
  enabled,
  limit,
  sandboxProjectId,
  status,
  type,
}: {
  enabled?: boolean
  limit?: number
  sandboxProjectId?: number
  status?: OptJobStatus
  type: OptJobType
}): UseQueryResult<OptJobStatusObject | OptJobStatusObject[] | []> {
  const { activeSystem } = useAuth()

  return useQuery(
    sandboxProjectId !== undefined
      ? getOptJobLatestKey({ sandboxProjectId, type, status })
      : getOptJobLatestKey({ systemId: activeSystem?.id, type, status }),
    () => {
      const queryParams: {
        opt_job_type__name: OptJobType
        system?: number
        limit?: number
        sandbox_project?: number
        status?: OptJobStatus
      } = {
        opt_job_type__name: type,
        limit: limit,
        status,
      }

      if (sandboxProjectId !== undefined) {
        queryParams.sandbox_project = sandboxProjectId
      } else {
        queryParams.system = activeSystem?.id
      }

      return apiClient<OptJobStatusObject[] | []>(`opt_job/latest_status`, {
        params: queryParams,
      }).then((response) => response ?? [])
    },
    {
      enabled: enabled ?? true,
    }
  )
}

export function useOptJobMutation() {
  const { t } = useTranslation()
  const { error } = useAlert()
  const { activeSystem } = useAuth()

  return useMutation(
    (data: OptJobPostData) =>
      apiClient(`opt_jobs`, {
        method: `POST`,
        data: { ...data, system: activeSystem?.id },
      }),
    {
      onMutate: async ({ opt_model, opt_job_type }: OptJobPostData) => {
        return setOptJobLatestQueryData({
          systemId: activeSystem?.id,
          type: opt_job_type,
          data: { status: `Created` },
        })
      },
      onError: (errors, _, rollback) => {
        rollback?.()
        error(t(`Something went wrong unfortunately, see error: `) + errors)
      },
      onSuccess: (_, { opt_job_type }: OptJobPostData) => {
        queryClient.invalidateQueries(
          getOptJobLatestKey({
            systemId: activeSystem?.id,
            type: opt_job_type,
          }),
          { refetchInactive: true }
        )
      },
    }
  )
}

export async function setOptJobLatestQueryData({
  systemId,
  type,
  status,
  data: newData,
}: {
  systemId?: number
  type: OptJobType
  status?: OptJobStatus
  data: Partial<OptJobStatusObject>
}) {
  const queryKey = getOptJobLatestKey({ systemId, type, status })
  await queryClient.cancelQueries(queryKey)

  const previousOptJob = queryClient.getQueryData<OptJobStatusObject | null>(queryKey, { exact: true })

  queryClient.setQueryData<Partial<OptJobStatusObject> | null | undefined>(queryKey, (data) => {
    if (data == null) return { status: newData.status }
    return { ...data, ...newData }
  })

  return () => queryClient.setQueryData(queryKey, previousOptJob)
}
